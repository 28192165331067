.p-togglebutton {
    &.p-button {
        padding: 0.25rem;

        .p-button-label {
            padding: 0.25rem 0.5rem;
        }

        .p-button-icon {
            padding: 0.35rem 0.25rem;
        }

        .p-button-label,
        .p-button-icon {
            position: relative;
            transition: none;
        }

        &::before {
            content: '';
            background-color: transparent;
            transition: $formElementTransition;
            position: absolute;
            left: .25rem;
            top: .25rem;
            width: calc(100% - .5rem);
            height: calc(100% - .5rem);
            border-radius: 4px;
        }
    }

    &.p-highlight {
        .p-button {
            &::before {
                background: $shade800;
                border-color: $shade800;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
            }
        }
    }

    &.p-highlight {
        .p-button-label {
            background: $shade800;
            border-color: $shade800;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
            border-radius:4px;
        }

        &:has(.p-button-icon) {
            .p-button-icon {
                margin-right: 0;
                background: $shade800;
                box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.02), -2px 2px 2px 0px rgba(0, 0, 0, 0.04);
                border: none;
                border-radius: 4px 0px 0px 4px;
            }
            
            .p-button-label {
                background: $shade800;
                box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.02), 2px 2px 2px 0px rgba(0, 0, 0, 0.04);
                border-radius: 0px 4px 4px 0px;
            }
            
        }
    }

    &:has(:not(.p-highlight)) {
        &:has(.p-button-icon) {
            .p-button-icon {
                margin-right: 0;
                border: none;
                border-radius: 4px 0px 0px 4px;
            }

            .p-button-label {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }

    &.p-disabled {
        opacity: 1;
        color: $shade500;
        .p-button-icon {
            color: $shade500;
        }
    }
}
.p-inline-message-text {
    font-weight: 500;
}

.p-inline-message {
    backdrop-filter: blur(10px);
}

.p-inline-message.p-inline-message-info {
    box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
}

.p-inline-message.p-inline-message-success {
    box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
}

.p-inline-message.p-inline-message-warn {
    box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
}

.p-inline-message.p-inline-message-error {
    box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
}

.p-inline-message.p-inline-message-secondary {
    box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
}

.p-inline-message.p-inline-message-contrast {
    box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
}

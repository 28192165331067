@layer mship-primeng-overrides {
  // breadcrumb ---------------------------------------
  .p-breadcrumb {
    padding-top: 0;
    padding-bottom: 0.5rem;
  }

  // card ---------------------------------------------
  .p-card {
    border-radius: var(--mship-layout-border-radius);

    .p-card-header {
      border-radius: var(--mship-layout-border-radius) var(--mship-layout-border-radius) 0 0;
      overflow: hidden;
    }
  }

  // overlayPanel -------------------------------------
  .p-overlaypanel {
    margin: 1rem 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
    max-height: 90%;
    overflow: auto;

    .p-tree {
      background-color: transparent;
    }
  }

  // form components ----------------------------------
  .p-dropdown-label.p-placeholder {
    opacity: 0.7;
  }

  // table ----------------------------------
  .p-datatable {
    border: 0.1rem solid var(--surface-300);
    border-radius: var(--mship-layout-border-radius);

    .p-datatable-wrapper {
      border-top-left-radius: var(--mship-layout-border-radius);
      border-top-right-radius: var(--mship-layout-border-radius);
    }

    .p-datatable-header ~ .p-datatable-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  p-table {
    tr {
      th:last-child,
      td:last-child {
        border-right: 4px solid transparent;
      }
    }
  }

  // button
  td,
  th {
    button.p-button[size='small'],
    p-button[size='small'] > button,
    p-splitbutton[size='small'] > div > button {
      padding: 0 0.4rem;
    }
  }

  // message ----------------------------------
  .p-message-detail {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // tabmenu ----------------------------------
  .p-tabmenu {
    .p-tabmenu-nav {
      border: 0;

      .p-tabmenuitem .p-menuitem-link {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      .p-tabmenu-ink-bar {
        display: none;
      }
    }
  }

  // stepper ----------------------------------
  .p-stepper-panels {
    background-color: transparent;
    padding: 2rem 0.5rem 1rem 0.5rem;
  }

  // accordion ----------------------------------
  .p-accordion-content {
    padding-top: 2rem;
    margin-top: -1rem;
  }

  // tabview ----------------------------------
  .p-card {
    .p-tabview .p-tabview-panels {
      background-color: var(--surface-100);
    }

    .p-card {
      .p-tabview .p-tabview-panels {
        background-color: var(--surface-50);
      }
    }
  }

  .p-tabview-ink-bar {
    display: none;
  }

  // togglebutton ----------------------------------
  .p-togglebutton.p-button.p-highlight {
    background: var(--primary-500);
    border-color: var(--primary-500);
  }
}

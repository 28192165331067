$contentBorderRadius: 12px;

$inputShadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);

@mixin secondary-button {
    color: $secondaryButtonTextColor;
    background: $secondaryButtonBg;
    border: $secondaryButtonBorder;

    &:not(:disabled):hover {
        background: $secondaryButtonHoverBg;
        color: $secondaryButtonTextHoverColor;
        border-color: $secondaryButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $secondaryButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $secondaryButtonActiveBg;
        color: $secondaryButtonTextActiveColor;
        border-color: $secondaryButtonActiveBorderColor;
    }
}

@layer primeng {
    @import "./_accordion";
    @import "./_autocomplete";
    @import "./_button";
    @import "./_calendar";
    @import "./_card";
    @import "./_carousel";
    @import "./_cascadeselect";
    @import "./_checkbox";
    @import "./_chips";
    @import "./_chip";
    @import "./_colorpicker";
    @import "./_confirmpopup";
    @import "./_datatable";
    @import "./_dataview";
    @import "./_dialog";
    @import "./_dropdown";
    @import "./_treetable";
    @import "./_fieldset";
    @import "./_filter";
    @import "./_fileupload";
    @import "./_galleria";
    @import "./_inlinemessage";
    @import "./_inputgroup";
    @import "./_inputnumber";
    @import "./_inputswitch";
    @import "./_inputtext";
    @import "./_knob";
    @import "./_listbox";
    @import "./_message";
    @import "./_multiselect";
    @import "./_panelmenu";
    @import "./_password";
    @import "./_orderlist";
    @import "./_organizationchart";
    @import "./_overlaypanel";
    @import "./_panel";
    @import "./_picklist";
    @import "./_progressbar";
    @import "./_radiobutton";
    @import "./_rating";
    @import "./_selectbutton";
    @import "./_slider";
    @import "./_speeddial";
    @import "./_splitter";
    @import "./_stepper";
    @import "./_steps";
    @import "./_tabmenu";
    @import "./_tabview";
    @import "./_tag";
    @import "./_terminal";
    @import "./_textarea";
    @import "./_timeline";
    @import "./_toast";
    @import "./_togglebutton";
    @import "./_toolbar";
    @import "./_tree";
    @import "./_treeselect";
}

@layer mship-design.base {
  html {
    font-size: 15px;
    height: 100vh;
    overflow: hidden;

    body {
      margin: 0;
      background-color: var(--surface-ground);

      main {
        height: 100vh;
        padding: 1rem 2rem 6rem 2rem;
        overflow: auto;
      }

      ::placeholder {
        opacity: 0.7;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    html {
      body {
        main {
          padding: 1rem 1rem 6rem 1rem;
        }
      }
    }
  }

  a {
    &.p-button {
      text-decoration: none;
    }

    &:not(.p-button, .p-accordion-header-link) {
      color: var(--primary-color);
    }
  }

  // form ---------------------------------------
  .p-dropdown.p-disabled.p-focus,
  main [ng-reflect-readonly="true"] .p-dropdown,
  input:read-only,
  select:read-only {
    outline: 0;
  }

  // card ---------------------------------------
  .mship-card-header {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;

    h1, h2 {
      margin: 0 0 1.5rem;
      font-size:1.5rem;
    }

    .mship-card-header-actions {
      display: flex;
      gap: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  .p-card-content > h1,
  .p-card-content > h2,
  .p-card-content > h3,
  .p-card-content > h4 {
    margin-top: 0;
  }

  // logo ---------------------------------------
  .mship-logo,
  .mship-logo-negative,
  .mship-logo-notext,
  .mship-logo-negative-notext {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .mship-logo-notext,
  .mship-logo-negative-notext {
    margin: 0.5rem 1.5rem;
  }

  .mship-logo {
    background-image: url($basePath + "assets/logo/MSHIP_Signet_RGB.svg");
  }

  .mship-logo-negative,
  .mship-theme-dark .mship-logo {
    background-image: url($basePath + "assets/logo/MSHIP_Signet_RGB_negativ.svg");
  }

  .mship-logo-notext {
    background-image: url($basePath + "assets/logo/MSHIP_Signet_RGB_notext.svg");
  }

  .mship-logo-negative-notext,
  .mship-theme-dark .mship-logo-notext {
    background-image: url($basePath + "assets/logo/MSHIP_Signet_RGB_negativ_notext.svg");
  }

  // layout legacy -------------------------------------
  .mship-group-form {
    display: inline-block;
    width: 100%;
  }

  // layout -------------------------------------
  .mship-spacer-2 {
    width: 100%;
    height: 2rem;
  }

  .mship-section-group {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    & > * {
      flex-grow: 1;
    }
  }

  .mship-group-actions {
    width: 100%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
    gap: 1rem;

    & > *:only-child {
      justify-self: flex-end;
    }

    .mship-group-actions-left {
      justify-self: flex-start;
      display: flex;
      gap: 1rem;
    }

    .mship-group-actions-right {
      justify-self: flex-end;
      display: flex;
      gap: 1rem;
    }
  }

  .mship-group-input {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .mship-label {
      margin: 0 0 0.5rem 0.1rem;
    }

    .p-component:not(.p-checkbox) {
      width: 100%;
    }
  }

  .mship-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 2rem;
  }

  main .p-card,
  .mship-section-group > * {
    &.mship-width-sm {
      width: 100%;
      max-width: 35rem;
    }
    &.mship-width-md {
      width: 100%;
      max-width: 68rem;
    }
    &.mship-width-lg {
      width: 100%;
      max-width: 90rem;
    }
    &.mship-width-xl {
      width: 100%;
      max-width: 112rem;
    }
  }

  .mship-hidden {
    display: none;
  }
}

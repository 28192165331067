.p-selectbutton {
    .p-button {
        .pi,
        .p-button-label {
            position: relative;
            transition: none;
        }
    
        &::before {
            content: '';
            background-color: transparent;
            transition: $formElementTransition;
            position: absolute;
            left: .25rem;
            top: .25rem;
            width: calc(100% - .5rem);
            height: calc(100% - .5rem);
            border-radius: 4px;
        }
    
        &.p-highlight {
            &::before {
                background: $shade800;
                border-color: $shade800;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
            }
        }

        &:focus-visible {
            @include focused-input();
        }

        &.p-disabled {
            opacity: 1;
            color: $shade500;
        }
    }

    &.p-invalid {
        box-shadow: 0 0 0 1px $errorColor;
        border-radius: $borderRadius;

        > .p-button {
            border: $toggleButtonBorder;
        }
    }

    &.p-disabled {
        opacity: 1;

        .p-button {
            color: $shade500;
        }
    }
}
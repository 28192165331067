:root {
  /* primeng global overrides */
  font-family: "MSHIP", sans-serif;
  --font-family: "MSHIP", sans-serif;

  // MSHIP variables
  --mship-font-size-xxlarge: 3rem;
  --mship-font-size-xlarge: 2rem;
  --mship-font-size-large: 1.4rem;
  --mship-font-size-medium: 1.2rem;
  --mship-font-size-regular: 1rem;
  --mship-font-size-small: 0.8rem;
  --mship-layout-border-radius: 0.4rem;
  --mship-element-border-with: 0.3rem;
}

@use 'sass:math';

.p-tag {
    background: color-mix(in srgb, var(--primary-500), transparent 80%);
    color: var(--primary-300);

    &.p-tag-success {
        background: $successMessageBg;
        color: $successMessageTextColor;
    }

    &.p-tag-info {
        background: $infoMessageBg;
        color: $infoMessageTextColor;
    }

    &.p-tag-warning {
        background: $warningMessageBg;
        color: $warningMessageIconColor;
    }

    &.p-tag-danger {
        background: $errorMessageBg;
        color: $errorMessageTextColor;
    }
}
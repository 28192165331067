  @layer mship-design.variables {
  :root {
    --mship-color-disabled: var(--grau-100);
    --mship-disabled-bg: var(--surface-200);
  }
}

@layer mship-design.base {
  body {
    min-height: 100vh;
    background-color: var(--surface-50);
    background-image: url(../../assets/background/transnet-background-dark.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@layer mship-primeng-overrides {
  :root {
    --primary-color-text: #ffffff;
  }

  // breadcrumb -----------------------------------------
  .p-breadcrumb {
    margin-bottom: 1rem;
  }

  // form -----------------------------------------------
  main input:not([readonly]),
  main select:not([readonly]),
  .p-dropdown:not(.p-disabled) {

    &.p-paginator-rpp-options {
      background-color: var(--surface-200);

      .p-dropdown-trigger {
        color: var(--mship-color-disabled);
      }
    }
  }

  input:hover:not([readonly]),
  input:focus:not([readonly]),
  select:hover:not([readonly]),
  select:focus:not([readonly]),
  textarea:hover:not([readonly]),
  textarea:focus:not([readonly]),
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
  main .p-dropdown:not(.p-disabled):hover,
  .p-dropdown:not(.p-disabled).p-focus,
  .p-multiselect:not(.p-disabled):focus,
  .p-multiselect-panel:not(.p-disabled):focus,
  .p-multiselect:not(.p-disabled):hover,
  .p-multiselect-panel:not(.p-disabled):hover,
  .p-multiselect .p-checkbox-box,
  .p-multiselect-panel .p-checkbox-box {
    background-color: var(--petrol-100);
    color: var(--primary-color-text);

    &::placeholder {
      color: var(--petrol-500);
    }

    .p-placeholder span {
      color: var(--petrol-500);
    }
  }

  .p-multiselect,
  .p-multiselect-panel,
  .p-multiselect:hover,
  .p-multiselect-panel:hover {
    .p-checkbox-box {
      .p-checkbox-icon {
        color: var(--petrol-600);
      }
    }

    .p-multiselect-item {
      &:not(.p-highlight):not(.p-disabled).p-focus {
        background-color: var(--petrol-200);
      }

      .p-checkbox + span {
        color: var(--primary-color-text);
      }
    }

    .p-multiselect-item:hover,
    .p-multiselect-item:focus {
      background-color: var(--petrol-200);
    }

    .p-multiselect-filter {
      background-color: transparent;
      color: var(--primary-color-text);
    }

    .p-multiselect-close {
      color: var(--petrol-400);

      &:hover {
        color: var(--text-color);
      }
    }

    .p-multiselect-filter-icon {
      color: var(--petrol-400);
    }
  }

  .p-multiselect-panel {
    .p-multiselect-items {
      padding: 1rem 0.25rem;

      .p-multiselect-item {
        color: var(--primary-color-text);
      }

      .p-multiselect-empty-message {
        color: var(--primary-color-text);
      }
    }

    .p-multiselect-footer {
      & > div {
        display: flex;
        gap: 1rem;
        border-top: 0.1rem solid var(--petrol-500);
        padding: 0.5rem;
        background-color: var(--petrol-200);
      }
    }
  }

  .p-dropdown-panel .p-dropdown-header {
    background-color: transparent;

    input {
      outline: none;
      background-color: transparent;
    }
  }

  main .p-dropdown:not(.p-disabled):hover *,
  .p-dropdown:not(.p-disabled).p-focus * {
    color: var(--primary-color-text);
  }

  main p-dropdown.mship-readonly .p-dropdown:not(.p-disabled):hover,
  p-dropdown.mship-readonly .p-dropdown:not(.p-disabled).p-focus {
    background-color: var(--surface-200);
  }

  main p-dropdown.mship-readonly .p-dropdown:not(.p-disabled):hover span,
  p-dropdown.mship-readonly .p-dropdown:not(.p-disabled).p-focus span {
    color: #ffffff;
  }

  [readonly],
  .mship-readonly .p-dropdown,
  .mship-readonly .p-checkbox-box,
  .p-dropdown.p-disabled.p-focus,
  main .mship-readonly .p-dropdown,
  input:read-only,
  select:read-only {
    background-color: var(--surface-200);
    border-color: var(--surface-300);
  }

  .p-inputtext:disabled {
    color: var(--surface-400);
    border-color: var(--surface-300);
  }

  // card ------------------------------------------
  .p-card {
    background-color: var(--surface-50);

    .p-card {
      background-color: var(--surface-100);
    }
  }

  // table ------------------------------------------
  p-table {
    border-radius: var(--mship-layout-border-radius);

    .p-datatable-header {
      border-top-left-radius: var(--mship-layout-border-radius);
      border-top-right-radius: var(--mship-layout-border-radius);
    }

    tr {
      &.changed {
        color: var(--signalrot-200);

        td:last-child {
          border-right-color: var(--signalrot-300);
        }
      }
    }
  }

  // paginator ------------------------------------------
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: var(--petrol-700);
  }

  p-table .p-paginator-bottom {
    border-bottom-left-radius: var(--mship-layout-border-radius);
    border-bottom-right-radius: var(--mship-layout-border-radius);
  }

  // steps ----------------------------------------------
  .p-steps {
    a {
      background-color: transparent;

      .p-steps-number {
        background-color: var(--surface-100);
        color: var(--surface-400);
      }

      .p-steps-title {
        color: var(--surface-400);
      }
    }

    .p-steps-item.p-highlight {
      .p-steps-number {
        background-color: $primaryColor;
        color: var(--surface-50);
      }

      .p-steps-title {
        color: $primaryColor;
      }
    }
  }

  // stepper
  .p-highlight .p-stepper-number {
    background: var(--lime-500);
    color: var(--surface-50);
  }

  // overlay --------------------------------------------
  .p-overlaypanel {
    background-color: var(--surface-200);
  }

  // message --------------------------------------------
  p-message {
    background-color: var(--surface-50);
    display: block;
    border-radius: var(--mship-layout-border-radius);
  }

  // tree
  .p-card {
    .p-tree {
      background-color: var(--surface-100);
    }

    .p-card {
      .p-tree {
        background-color: var(--surface-50);
      }
    }
  }

  // selectbutton
  .p-card {
    .p-selectbutton .p-button {
      background-color: var(--surface-100);
    }

    .p-card {
      .p-selectbutton .p-button {
        background-color: var(--surface-50);

        &.p-highlight::before {
          background-color: var(--surface-100);
        }
      }
    }
  }
}

@layer mship-design.appshell {

}

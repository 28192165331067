@layer mship-design.appshell {
  .mship-theme-light .mship-app-shell-header {
    background-color: var(--surface-200);
    border-color: var(--lime-500);
  }

  .mship-theme-dark .mship-app-shell-header {
    background-color: var(--surface-100);
    border-color: var(--petrol-500);
  }

  .mship-app-shell-header {
    position: relative;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    background-color: var(--surface-200);
    border-width: 0.2rem;
    border-style: solid;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;

    .mship-app-shell-logolabel {
      display: flex;
      align-items: center;
      max-width: 100%;

      .mship-app-shell-logo {
        width: 4.5rem;
        height: 3.5rem;
        margin: 0 2rem 0 1rem;
      }

      .mship-app-shell-label {
        display: flex;
        flex-direction: column;

        .mship-app-shell-headline,
        .mship-app-shell-subheadline {
          color: var(--text-color);
        }

        .mship-app-shell-headline {
          font-size: 1.2rem;
          font-weight: bold;
        }

        .mship-app-shell-subheadline {
          font-size: 0.9rem;
        }
      }
    }

    .mship-app-shell-menubar {
      width: 100%;
      margin-right: 2rem;
    }

    .mship-app-shell-actions {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1.5rem;
      margin: 0.5rem 0 0.5rem 2rem;

      .mship-app-shell-action:last-child {
        margin-right: 1.5rem;
      }

      .pi {
        color: var(--primary-500);
        font-size: 1.3rem;
        margin-top: 0.1rem;
        cursor: pointer;

        &.p-menuitem-icon {
          margin-top: 0;
        }
      }

      .mship-theme-light & .pi {
        color: var(--primary-500);
      }

      .mship-theme-dark & .pi {
        color: var(--lime-500);
      }

      .p-button-icon-only {
        width: auto;
      }

      .mship-button-with-badge {
        .p-button {
          overflow: visible;
        }
      }

      .app-shell-menubutton {
        border: 0;
        padding: 0;
        margin: 0;
        background: transparent;
        border-radius: 0;
      }

      .mship-app-shell-action-version {
        font-size: 0.8rem;
        color: var(--surface-400);
      }

      .mship-theme-dark & .p-dropdown:not(.p-inputwrapper-focus) {
        background-color: var(--surface-200);

        .p-dropdown-trigger {
          color: var(--surface-500);
        }
      }

      .p-menu.p-menu-overlay {
        background-color: var(--surface-50);
      }
    }
  }

  .mship-app-shell-menu-wrapper {
    z-index: 9999 !important;
    max-width: 42.7rem;
    min-width: 42.7rem;

    .mship-app-shell-menu {
      display: flex;
      flex-direction: column;

      .mship-app-shell-menu-searchwrapper {
        margin-bottom: 1rem;
        padding: 0.4rem;
        position: sticky;
        top: 0;
        background-color: var(--surface-200);
        z-index: 1;

        .p-input-icon-right {
          width: 100%;
        }

        .mship-theme-dark & i {
          color: var(--surface-300);
        }

        input {
          width: 100%;
        }

        .mship-theme-dark & input {
          background-color: var(--surface-100);

          &::placeholder {
            color: var(--surface-400);
          }

          &:hover,
          &:focus {
            background-color: var(--petrol-100);

            &::placeholder {
              color: var(--petrol-300);
            }
          }
        }
      }

      .mship-app-shell-menu-group {
        padding: 0.5rem;
        cursor: pointer;

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: left;
          gap: 1rem;
          padding: 0;

          li {
            position: relative;
            cursor: pointer;
            background-color: var(--surface-100);
            list-style: none;
            max-width: 7rem;
            border-radius: var(--mship-layout-border-radius);

            .mship-theme-dark & {
              background-color: var(--surface-300);
            }

            &.mship-app-shell-menu-favorite {
              background-color: var(--surface-300);
            }

            .mship-theme-light &.mship-app-shell-menu-favorite {
              background-color: var(--lime-200);

              a {
                opacity: 1;

                i {
                  color: var(--primary-color);
                }
              }
            }

            .mship-theme-dark &.mship-app-shell-menu-favorite {
              background-color: var(--petrol-800);

              a {
                opacity: 1;

                i {
                  color: var(--primary-color);
                }
              }
            }

            &:hover {
              background-color: var(--surface-50);
            }

            a {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-decoration: none;
              opacity: 0.7;
              padding: 0.8rem;

              i {
                font-size: 1.8rem;
                color: var(--text-color);
                margin-bottom: 0.5rem;
              }

              span {
                font-size: 0.9rem;
                color: var(--text-color);
                text-align: center;
                hyphens: auto;
              }
            }

            .mship-app-shell-menu-favstar {
              opacity: 0.5;
              color: var(--surface-400);
              position: absolute;
              top: 0.3rem;
              right: 0.3rem;

              &.pi-star-fill {
                color: var(--senfgelb-500);
              }
            }
            .mship-theme-dark & .mship-app-shell-menu-favstar {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  // app shell orientation styling -------------------------------------------

  @media (max-width: 960px) {
    .mship-app-shell-notification * {
      font-size: var(--mship-font-size-small);
    }
  }

  .mship-direction-switcher-top,
  .mship-direction-switcher-bottom {
    .mship-app-shell-header {
      .mship-app-shell-actions-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
      }

      @media (max-width: 1370px) {
        .mship-app-shell-notification {
          position: fixed;
          bottom: 2rem;
          left: 50%;
          transform: translate(-50%) scale(1.2);
        }
      }
    }

    main {
      height: calc(100vh - 3.7rem);
    }
  }

  .mship-direction-switcher-bottom {
    .mship-app-shell-header {
      @media (max-width: 1370px) {
        .mship-app-shell-notification {
          bottom: 5rem;
        }
      }
    }

    main {
      margin-bottom: 3.7rem;
    }

    .p-overlaypanel {
      margin: -1rem 0;
      bottom: 4rem;
      top: auto !important;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .mship-direction-switcher-left,
  .mship-direction-switcher-right,
  .mship-direction-switcher-top,
  .mship-direction-switcher-bottom {
    .mship-app-shell-header {
      justify-content: flex-start;

      .mship-app-shell-actions:first-child {
        margin-left: 0;
      }

      .mship-app-shell-actions-wrapper {
        .mship-app-shell-actions {
          .mship-app-shell-action {
            .p-button {
              background-color: transparent;
              border: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .mship-direction-switcher-left,
  .mship-direction-switcher-right {
    .mship-app-shell-header {
      width: 4.6rem;
      max-width: 4.6rem;
      float: left;
      height: 100vh;
      border-width: 0 0.2rem 0 0;
      flex-direction: column;
      display: flex;
      justify-content: flex-start;

      .mship-app-shell-logolabel {
        .mship-app-shell-logo {
          margin: 0;
        }

        .mship-app-shell-headline,
        .mship-app-shell-subheadline {
          display: none;
        }
      }

      .mship-app-shell-actions-wrapper {
        max-width: 100%;
        flex-grow: 1;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .mship-app-shell-actions {
          max-width: 100%;
          gap: 1.5rem;
          margin: 0;
          padding: 0;
          flex-direction: column;
          justify-content: center;

          &.bottom {
            margin-bottom: 2rem;
          }

          .mship-app-shell-action {
            &:last-child {
              margin-right: 0;
            }

            .p-button {
              background-color: transparent;
              border: 0;
            }

            &.mship-app-shell-action-menu {
              margin-right: 0;
            }

            &.mship-app-shell-action-version {
              text-align: center;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding: 0 0.5rem;
            }
          }

          .mship-app-shell-notification {
            position: fixed;
            bottom: 2rem;
            left: 50%;
            transform: translate(-50%) scale(1.2);
          }
        }
      }
    }
  }

  .mship-direction-switcher-top {
    .mship-app-shell-header {
      border-width: 0 0 0.2rem;
    }
  }

  .mship-direction-switcher-right {
    .mship-app-shell-header {
      float: right;
      border-width: 0 0 0 0.2rem;
    }
  }

  .mship-direction-switcher-bottom {
    .mship-app-shell-header {
      border-width: 0.2rem 0 0;
      position: absolute;
      bottom: 0;
      top: auto;
      margin-bottom: 0;
    }
  }
}

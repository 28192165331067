// PrimeNG defaults
$primaryColor: #b2bb1c !default;
$primaryLightColor: #e1e3bc !default;
$primaryLighterColor: #e1e3bc !default;
$primaryTextColor: #020617 !default;

$highlightBg: rgba(34, 211, 238, .16) !default;
$highlightTextColor: rgba(255,255,255,.87) !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../mship/_layer-def'; // MSHIP CSS layer priority definition
@import './_mship.variables-sass'; // MSHIP scss (mostly overrides)

@import 'primeicons/primeicons.css';
@import '../../_compiler';
@import './_variables';
@import '../theme-base/_components';
@import './extensions/_extensions';
@import '../../../../node_modules/primeng/resources/primeng';

@import './_mship.variables-css'; // MSHIP css (mostly overrides)
@import '../mship/_mship'; // MSHIP css includes

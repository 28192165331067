@import '../mship/_colors'; // MSHIP scss/css

//reused color
$shade000: #ffffff;
$shade50: #fafafa;
$shade100: #f4f4f5;
$shade200: #e4e4e7;
$shade300: #d4d4d8;
$shade400: #a1a1aa;
$shade500: #71717a;
$shade600: #52525b;
$shade700: #3f3f46;
$shade800: #27272a;
$shade900: #18181b;
$shade950: #09090b;

// primeng theme global overrides
$textColor: $shade000;
$highlightTextColor: rgba(255,255,255,.87);
$disabledInputBg: var(--mship-disabled-bg);
$hoverBg: rgba(127, 166, 172, 1);
$textSecondaryColor: var(--lime-500);

$fontFamily: "MSHIP", sans-serif;
$fontSize: 1rem;

$primaryColor: $mshipColorLime;
$primaryDarkColor: #295f67; // petrol-600
$primaryDarkerColor: #224e55; // petrol-700
$errorColor: #ca4348; // signalrot-400

$highlightBg: $mshipColorPetrolLight;

$primeIconFontSize: 1rem;
$loadingIconFontSize: 1rem;

//focus
$focusOutline: 0.25rem solid var(--lime-500);
$inputFocusOutlineOffset: 0.18rem;

//breadcrumb
$breadcrumbPadding: 0 0 1rem 0.1rem;
$breadcrumbBg: transparent;
$breadcrumbItemTextColor: var(--lime-500);
$breadcrumbItemIconColor: var(--lime-500);
$breadcrumbLastItemTextColor: var(--lime-500);
$breadcrumbLastItemIconColor: var(--lime-500);
$breadcrumbSeparatorColor: var(--lime-500);

//input field (e.g. inputtext, spinner, inputmask)
$inputBg: var(--petrol-600);
$inputIconColor: var(--petrol-200);
$inputPlaceholderTextColor: var(--petrol-300);
$inputBorder: 0.2rem solid var(--petrol-500);

//checkbox
$checkboxWidth: 1.5rem;
$checkboxHeight: 1.5rem;
$checkboxBorder: 0.2rem solid var(--petrol-500);
$checkboxActiveBorderColor: var(--petrol-500);
$checkboxActiveBg: var(--petrol-600);
$checkboxIconActiveColor: $highlightTextColor;
$checkboxActiveHoverBg: var(--petrol-600);
$checkboxIconActiveHoverColor: $highlightTextColor;
$checkboxActiveHoverBorderColor: var(--petrol-500);

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: var(--petrol-100);
$inputOverlayHeaderBg: var(--petrol-100);

//menu (e.g. menu, menubar, tieredmenu)
$menuBg: var(--surface-100);
$menuBorder: 1px solid var(--surface-100);
$submenuHeaderBg: var(--surface-100);
$submenuHeaderTextColor: white;

// card
$cardTitleFontSize: 1.5rem;
$cardSubTitleColor: $textColor;

// button
$buttonBorder: 0.2rem solid $primaryColor;
$outlinedButtonBorder: 0.2rem solid;

$buttonIconOnlyWidth: 2.5rem;

$secondaryButtonBg: var(--petrol-300);
$secondaryButtonTextColor: $mshipColorSchwarz;
$secondaryButtonBorder: 1px solid var(--petrol-300);
$secondaryButtonHoverBg: var(--lime-300);
$secondaryButtonTextHoverColor: $mshipColorSchwarz;
$secondaryButtonHoverBorderColor: var(--lime-200);
$secondaryButtonActiveBg: var(--lime-300);
$secondaryButtonTextActiveColor: $mshipColorSchwarz;
$secondaryButtonActiveBorderColor: var(--lime-300);

$dangerButtonBg: $mshipColorSignalrot;
$dangerButtonBorder: 1px solid $mshipColorSignalrot;
$dangerButtonHoverBg: var(--signalrot-600);
$dangerButtonHoverBorderColor: var(--signalrot-600);
$dangerButtonActiveBg: var(--signalrot-700);
$dangerButtonActiveBorderColor: var(--signalrot-700);

//table
$tableHeaderBg: var(--surface-300);
$tableHeaderCellBg: var(--surface-300);
$tableHeaderCellIconColor: var(--grau-100);
$tableHeaderCellIconHoverColor: var(--petrol-700);
$tableHeaderCellHighlightBg: var(--petrol-700);
$tableBodyRowBg: var(--surface-200);
$tableFooterCellBg:  var(--surface-100);
$tableFooterBg: var(--surface-200);

//paginator
$paginatorBg: var(--surface-300);
$paginatorTextColor: $textColor;
$paginatorElementIconColor: $textColor;

//tabview
$tabviewNavBg: var(--surface-100);
$tabviewHeaderBg: var(--surface-100);
$tabviewHeaderActiveBg: var(--surface-50);

//severities
$infoMessageBg: rgba(59, 130, 246, 0.16);
$infoMessageBorder: solid rgba(29, 78, 216, 0.36);
$infoMessageTextColor: #3b82f6;
$infoMessageIconColor: #3b82f6;
$successMessageBg: rgba(34, 197, 94, 0.16);
$successMessageBorder: solid rgba(21, 128, 61, 0.36);
$successMessageTextColor: #22c55e;
$successMessageIconColor: #22c55e;
$warningMessageBg: rgba(234, 179, 8, 0.16);
$warningMessageBorder: solid rgba(161, 98, 7, 0.36);
$warningMessageTextColor: #eab308;
$warningMessageIconColor: #eab308;
$errorMessageBg: rgba(239, 68, 68, 0.16);
$errorMessageBorder: solid rgba(185, 28, 28, 0.36);
$errorMessageTextColor: #ef4444;
$errorMessageIconColor: #ef4444;
$secondaryMessageBg: $shade800;
$secondaryMessageBorder: solid $shade700;
$secondaryMessageTextColor: $shade300;
$secondaryMessageIconColor: $shade300;
$contrastMessageBg: $shade000;
$contrastMessageBorder: solid $shade100;
$contrastMessageTextColor: $shade950;
$contrastMessageIconColor: $shade950;

//steps
$stepsItemBg: transparent;
$stepsItemTextColor: var(--surface-400);
$stepsItemNumberColor:  var(--surface-400);

//accordion
$accordionHeaderTextColor: var(--text-color);
$accordionHeaderBg: var(--surface-300);
$accordionHeaderHoverBg: var(--surface-300);
$accordionHeaderActiveBg: var(--surface-300);
$accordionHeaderActiveHoverBg: var(--surface-300);
$accordionContentBg: transparent;
$accordionContentBorder: 0.1rem solid var(--surface-300);


:root {
  --mship-color-error: #ef4444;
  --mship-color-warning: #eab308;
  --mship-color-info: #3b82f6;
  --mship-color-success: #22c55e;
}

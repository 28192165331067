@layer mship-design.drag {
  .mship-drag-iframe {
    margin: 0;
    padding: 0;
    border: none;
    width: 1rem;
    height: 1.5rem;
    position: fixed;
  }
}

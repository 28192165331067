.p-dialog {
    border-radius: $contentBorderRadius;
    background-color: $dialogHeaderBg;

    &.p-dialog-maximized {
        border-radius: 0;
    }
    
    .p-dialog-header {
        border-top-right-radius: $contentBorderRadius;
        border-top-left-radius: $contentBorderRadius;
    }

    .p-dialog-content {
        &:last-of-type {
            border-bottom-right-radius: $contentBorderRadius;
            border-bottom-left-radius: $contentBorderRadius;
        }
    }

    .p-dialog-footer {
        border-bottom-right-radius: $contentBorderRadius;
        border-bottom-left-radius: $contentBorderRadius;
    }
}
$mshipColorPetrol: #307079;
$mshipColorPetrolLight: #004f5a21;
$mshipColorLime: #b2bb1c;
$mshipColorSchwarz: #000000;

$mshipColorHellblau: #7ba2a8;
$mshipColorHellgelb: #e9ebad;
$mshipColorGrau: #4d4d4e;

$mshipColorDunkelrot: #882631;
$mshipColorSignalrot: #be171d;
$mshipColorOrange: #f47723;
$mshipColorSenfgelb: #d89a40;
$mshipColorSignalblau: #1c788f;

:root {
  --mship-color-surface-0 {
    background-color: var(--surface-ground);
  }
}

@layer mship-design.fonts {
  /*
  LEXEND font:
  These fonts are licensed under the Open Font License. https://openfontlicense.org/
  You can use them in your products & projects – print or digital, commercial or otherwise.
  https://www.lexend.com/
  https://fonts.google.com/specimen/Lexend/about
   */

  // LEXEND
  @font-face {
    font-family: "MSHIP";
    font-display: auto;
    font-weight: 200;
    src: url($basePath + "assets/fonts/Lexend-ExtraLight.woff2") format("woff2");
  }

  @font-face {
    font-family: "MSHIP";
    font-display: auto;
    font-weight: normal;
    src: url($basePath + "assets/fonts/Lexend-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "MSHIP";
    font-display: auto;
    font-weight: 400;
    src: url($basePath + "assets/fonts/Lexend-Regular.woff2") format("woff2");
  }

  @font-face {
    font-family: "MSHIP";
    font-display: auto;
    font-weight: bold;
    src: url($basePath + "assets/fonts/Lexend-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "MSHIP";
    font-display: auto;
    font-weight: 700;
    src: url($basePath + "assets/fonts/Lexend-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "MSHIP";
    font-display: auto;
    font-weight: 900;
    src: url($basePath + "assets/fonts/Lexend-Black.woff2") format("woff2");
  }

  // Avenir Next
  /*
  @font-face {
    font-family: "MSHIP";
    font-weight: normal;
    font-display: auto;
    font-style: normal;
    src: url($basePath + "assets/fonts/avenir-next-400.woff2") format("woff2");
  }
  @font-face {
    font-family: "MSHIP";
    font-weight: normal;
    font-display: auto;
    font-style: italic;
    src: url($basePath + "assets/fonts/avenir-next-400-italic.woff2") format("woff2");
  }
  @font-face {
    font-family: "MSHIP";
    font-weight: bold;
    font-display: auto;
    font-style: normal;
    src: url($basePath + "assets/fonts/avenir-next-600.woff2") format("woff2");
  }
  @font-face {
    font-family: "MSHIP";
    font-weight: bold;
    font-display: auto;
    font-style: italic;
    src: url($basePath + "assets/fonts/avenir-next-600-italic.woff2") format("woff2");
  }
  */
}
